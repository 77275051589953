	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/01/2019 11:00
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: inherit;
  font-style: normal;
}

.flaticon-phone-call:before { content: "\f100"; }
.flaticon-layers:before { content: "\f101"; }
.flaticon-idea:before { content: "\f102"; }
.flaticon-responsive:before { content: "\f103"; }
.flaticon-bar-chart:before { content: "\f104"; }
.flaticon-market:before { content: "\f105"; }
.flaticon-sketch:before { content: "\f106"; }
.flaticon-loupe:before { content: "\f107"; }
.flaticon-graphic-design:before { content: "\f108"; }
.flaticon-target:before { content: "\f109"; }
.flaticon-analysis:before { content: "\f10a"; }
.flaticon-content:before { content: "\f10b"; }
.flaticon-research:before { content: "\f10c"; }
.flaticon-value:before { content: "\f10d"; }
.flaticon-analysis-1:before { content: "\f10e"; }
.flaticon-sway:before { content: "\f10f"; }
.flaticon-data:before { content: "\f110"; }
.flaticon-web:before { content: "\f111"; }
.flaticon-location:before { content: "\f112"; }
.flaticon-call:before { content: "\f113"; }
.flaticon-email:before { content: "\f114"; }
.flaticon-value-1:before { content: "\f115"; }
.flaticon-color-palette:before { content: "\f116"; }
.flaticon-tablet:before { content: "\f117"; }
.flaticon-speaker:before { content: "\f118"; }
.flaticon-mail:before { content: "\f119"; }
.flaticon-color-palette-1:before { content: "\f11a"; }
.flaticon-analysis-2:before { content: "\f11b"; }
.flaticon-research-1:before { content: "\f11c"; }